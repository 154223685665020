import styled from 'styled-components';
import { minBp } from '../../lib/theme';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 20px 20px 0;

  @media ${minBp('tabletLarge')} {
    margin-top: 0px;
    flex: 2;
    margin-right: 60px;
  }
`;

export const Logo = styled.img`
  height: 40px;
  margin-bottom: 20px;

  @media ${minBp('tabletLarge')} {
    height: 64px;
  }
`;

export const Title = styled.h4`
  font-family: 'Lato', serif;
  color: #0c2c64;
  margin-bottom: 24px;
  font-size: 16px;
  text-align: center;

  @media ${minBp('tabletLarge')} {
    font-size: 20px;
    max-width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const Intro = styled.p`
  font-family: 'Lato', serif;
  color: #0c2c64;
  margin-bottom: 30px;
  margin-top: 0;
  font-size: 14px;
  text-align: center;

  @media ${minBp('tabletLarge')} {
    font-size: 16px;
    max-width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const CTA = styled.button`
  font-family: 'Lato', serif;
  color: #fff;
  background-color: #0c2c64;
  padding: 10px 15px;
  text-decoration: none;
  font-size: 16px;
  outline: none;
  cursor: pointer;
  margin-bottom: 20px;

  @media ${minBp('tabletLarge')} {
    font-size: 18px;
    margin-bottom: 0;
  }
`;
