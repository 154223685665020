/* eslint-disable react/prop-types */
import React, { useEffect, createRef, useContext } from 'react';
import Map from './Map';
import { MapContainer, MapWrap, ContainerElement } from './Map.styles';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import { Context } from '../../pages/search-and-results/search-and-results';

const WrappedMap = ({
  isFilterSticky,
  developmentHover,
  developmentHoverLocation,
  error
}) => {
  const mapRef = createRef();
  let mapTarget = null;
  const mapContext = useContext(Context);

  const { showMap, showFilterMenu } = mapContext.state;

  useEffect(() => {
    if (showMap || showFilterMenu) {
      mapTarget = mapRef.current;
      disableBodyScroll(mapTarget);
    } else {
      clearAllBodyScrollLocks();
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [showMap]);

  useEffect(() => {
    const header = document.querySelector('header');

    if (!header) {
      return;
    }

    if (showMap) {
      header.classList.add('mobile-location-search');
    } else {
      header.classList.remove('mobile-location-search');
    }
  }, [showMap]);

  return (
    <MapContainer showMap={showMap} ref={mapRef}>
      <MapWrap showMap={showMap} isFilterSticky={isFilterSticky}>
        <Map
          googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDBRj0yyBtQ50o8geSjePHVbKr_7zXsrrk"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<ContainerElement />}
          mapElement={<div style={{ height: `100%` }} />}
          developmentHover={developmentHover}
          developmentHoverLocation={developmentHoverLocation}
          error={error}
        />
      </MapWrap>
    </MapContainer>
  );
};

export default WrappedMap;
