/* eslint-disable react/prop-types */
import React, {
  useState,
  createRef,
  useEffect,
  useContext,
  Fragment
} from 'react';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import Select from 'react-select';

import {
  MobileFiltersComponent,
  Wrap,
  InputWrap,
  Input,
  InputButton,
  InputIcon,
  FilterButton,
  FilterButtonText,
  FilterMenu,
  FilterInner,
  FilterOptionWrap,
  FilterLabelWrap,
  FilterLabel,
  Icon,
  ActionWrap,
  ActionInner,
  ClearBtn,
  ClearBtnText,
  ApplyBtn,
  ApplyBtnText,
  ResultsWrap,
  ResultsInner,
  ResultIcon,
  Result
} from './MobileFilters.styles.js';

import { ReactComponent as ChevronDownIcon } from '../../../assets/icons/ChevronDown.svg';
import SelectedMarker from '../../../assets/icons/SelectedMarker.svg';
import SearchIcon from '../../../assets/icons/Search.svg';
import FilterDropdown from '../../filter-dropdown/FilterDropdown.jsx';
import FilterSelector from '../../filter-selector/FilterSelector.jsx';

import ContentLabel from '../../content-label/ContentLabel';

import { Context } from '../../../pages/search-and-results/search-and-results';

import { customStyles } from '../../../utility/helpers';

const MobileFilters = ({
  filters,
  onSearchTrigger,
  handleKeyPressed,
  onQueryChange,
  results,
  onFetchProperties
}) => {
  const [selectedRadius, setSelectedRadius] = useState(null);
  const filterRef = createRef();

  const mobileFilterContext = useContext(Context);
  const radiusId = 'f67a0762-4ae8-46a8-a8b3-e43a9119a5c7';

  const { searchLocationLabel } = mobileFilterContext.state.contentData.labels;

  const {
    showMap,
    showFilterMenu,
    bedrooms,
    priceRange,
    radius,
    selectedPropertyTypes,
    developmentCount,
    plotCount,
    initialRadius
  } = mobileFilterContext.state;

  let filterTarget = null;
  let radiusDefault;

  if (mobileFilterContext.state.location) {
    radiusDefault = mobileFilterContext.state.location.radius;
  }

  let selectedPropertyTypeValues = [];

  selectedPropertyTypes.map(property => {
    return selectedPropertyTypeValues.push(Object.values(property).toString());
  });

  function toggleMenu() {
    mobileFilterContext.dispatch({
      type: 'SET_SHOW_FILTER_MENU',
      payload: !showFilterMenu
    });
  }

  function handleClearFilter() {
    mobileFilterContext.dispatch({
      type: 'RESET_BEDROOMS'
    });

    mobileFilterContext.dispatch({
      type: 'RESET_PRICE'
    });

    mobileFilterContext.dispatch({
      type: 'RESET_PROPERTY_TYPES'
    });
  }

  useEffect(() => {
    if (showFilterMenu || showMap) {
      filterTarget = filterRef.current;
      disableBodyScroll(filterTarget);
    } else {
      clearAllBodyScrollLocks();
    }

    return () => {
      clearAllBodyScrollLocks();
    };
  }, [showFilterMenu]);

  let radiusLabel;
  let radiusValue;

  // Set default label for the radius dropdown, might be overcomplicating it, but it works for now.
  if (filters && radiusDefault) {
    filters.forEach(filter => {
      if (filter.id !== radiusId) {
        return;
      }

      filter.values.forEach(value => {
        return Object.keys(value).map(function(e) {
          if (value[e] === radiusDefault.toString()) {
            radiusLabel = value.label;
            radiusValue = value[e];
          }
        });
      });
    });
  }

  function calculateFilterTotal() {
    let total = 0;

    if (bedrooms.min) {
      total += 1;
    }

    if (bedrooms.max) {
      total += 1;
    }

    if (priceRange.min) {
      total += 1;
    }

    if (priceRange.max) {
      total += 1;
    }

    if (selectedPropertyTypes.length) {
      total += selectedPropertyTypes.length;
    }

    return total;
  }

  return (
    <MobileFiltersComponent
      showFilterMenu={showFilterMenu}
      ref={filterRef}
      showMap={showMap}
    >
      <Wrap>
        <InputWrap>
          <InputButton onClick={() => onSearchTrigger()}>
            <InputIcon src={SearchIcon} />
          </InputButton>
          <Input
            onChange={e => onQueryChange(e.target.value)}
            onKeyPress={handleKeyPressed}
            placeholder={searchLocationLabel || ''}
            onFocus={e => (e.target.placeholder = '')}
          />
          {results && results.length ? (
            <ResultsWrap>
              {results.map((result, key) => (
                <Fragment key={key}>
                  {result.Name && result.County ? (
                    <ResultsInner>
                      <ResultIcon src={SelectedMarker} />
                      <Result
                        onClick={() => {
                          const resultString = `${result.Name}, ${result.County}`;

                          onQueryChange(resultString);
                          onSearchTrigger(resultString);
                          onFetchProperties(resultString);
                        }}
                      >
                        {result.Name}, {result.County}
                      </Result>
                    </ResultsInner>
                  ) : null}
                </Fragment>
              ))}
            </ResultsWrap>
          ) : null}
        </InputWrap>
        <FilterButton
          showFilterMenu={showFilterMenu}
          onClick={() => {
            toggleMenu();
            window.dataLayer.push({
              event: showFilterMenu ? 'FilterClose' : 'FilterOpen',
              filterSearchRadius: `${radius || initialRadius} miles`,
              filterPriceRangeMin: priceRange.min,
              filterPriceRangeMax: priceRange.max,
              filterBedroomsMin: bedrooms.min,
              filterBedroomsMax: bedrooms.max,
              filterPropertyType: selectedPropertyTypeValues,
              countDevelopments: developmentCount,
              countPlots: plotCount
            });
          }}
        >
          <FilterButtonText>
            <ContentLabel name="filtersLabel" /> ({calculateFilterTotal()})
          </FilterButtonText>
          <ChevronDownIcon />
        </FilterButton>
      </Wrap>
      {showFilterMenu ? (
        <FilterMenu>
          <FilterInner>
            {filters
              ? filters.map((filter, i) =>
                  filter.id !== 'b119da3d-3576-4829-9fff-67adf0ba12fb' ? ( //! REMOVE THIS CONDITIONAL ONCE PROPERTY TYPES HAS BEEN APPROVED TO BE ADDED, TEMP FIX!
                    <FilterOptionWrap key={i}>
                      <FilterLabelWrap>
                        {filter.icon ? <Icon src={filter.icon} /> : null}
                        {filter.title ? (
                          <FilterLabel>
                            {filter.id === radiusId ? ( // Use Search Distance label if it's the radius dropdown
                              <ContentLabel name="searchDistanceLabel" />
                            ) : (
                              filter.title
                            )}
                          </FilterLabel>
                        ) : null}
                      </FilterLabelWrap>
                      {filter.type === 'slider' ? (
                        <FilterDropdown
                          values={filter.values}
                          width="100%"
                          customStyles={customStyles}
                          handleClearFilter={() => handleClearFilter}
                          id={filter.id}
                          mobileFilters
                        />
                      ) : (
                        filter.type !== 'selection' && (
                          <FilterSelector
                            title={filter.title}
                            id={filter.id}
                            type={filter.type}
                            values={filter.values}
                          />
                        )
                      )}
                      {filter.type === 'selection' ? (
                        <Select
                          styles={customStyles}
                          width="100%"
                          display="flex"
                          backgroundColor="white"
                          fontSize="12px"
                          border="1px solid #e8e8e8"
                          options={filter.values}
                          color="#1d3c7c"
                          defaultValue={{
                            label: radiusLabel,
                            value: radiusValue
                          }}
                          components={{
                            IndicatorSeparator: () => null
                          }}
                          onChange={event => {
                            setSelectedRadius(event.value);
                          }}
                          isSearchable={false}
                        />
                      ) : null}
                    </FilterOptionWrap>
                  ) : null
                )
              : null}
          </FilterInner>
        </FilterMenu>
      ) : null}
      {showFilterMenu ? (
        <ActionWrap>
          <ActionInner>
            <ClearBtn
              onClick={() => {
                handleClearFilter();
                window.dataLayer.push({
                  event: 'FilterClearMobile',
                  filterSearchRadius: `${radius || initialRadius} miles`,
                  filterPriceRangeMin: priceRange.min,
                  filterPriceRangeMax: priceRange.max,
                  filterBedroomsMin: bedrooms.min,
                  filterBedroomsMax: bedrooms.max,
                  filterPropertyType: selectedPropertyTypeValues,
                  countDevelopments: developmentCount,
                  countPlots: plotCount
                });
              }}
            >
              <ClearBtnText>
                <ContentLabel name="clearLabel" />
              </ClearBtnText>
            </ClearBtn>
            <ApplyBtn
              onClick={() => {
                if (selectedRadius) {
                  mobileFilterContext.dispatch({
                    type: 'SET_RADIUS',
                    payload: selectedRadius
                  });
                }

                onFetchProperties();
                mobileFilterContext.dispatch({
                  type: 'SET_SHOW_FILTER_MENU',
                  payload: false
                });

                window.dataLayer.push({
                  event: 'FilterApplyMobile',
                  filterSearchRadius: `${radius || initialRadius} miles`,
                  filterPriceRangeMin: priceRange.min,
                  filterPriceRangeMax: priceRange.max,
                  filterBedroomsMin: bedrooms.min,
                  filterBedroomsMax: bedrooms.max,
                  filterPropertyType: selectedPropertyTypeValues,
                  countDevelopments: developmentCount,
                  countPlots: plotCount
                });
              }}
            >
              <ApplyBtnText>
                <ContentLabel name="applyLabel" />
              </ApplyBtnText>
            </ApplyBtn>
          </ActionInner>
        </ActionWrap>
      ) : null}
    </MobileFiltersComponent>
  );
};

export default MobileFilters;
