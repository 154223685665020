export const initialState = {
  contentData: {},
  location: null,
  searchedLocation: null,
  query: '',
  radius: null,
  bedrooms: {
    min: null,
    max: null,
  },
  priceRange: {
    min: null,
    max: null,
  },
  propertyType: {},
  selectedPropertyTypes: [],
  originalData: [], // All the development/houses,
  seeAllLocations: false,
  isLoading: false,
  activeFilter: null,
  toggleSearchInput: false,
  showMap: false,
  showFilterMenu: false,
  initialLocation: null,
  initialRadius: null,
  fallbackRadius: null,
  plotCount: 0,
  developmentCount: 0,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_RADIUS':
      return { ...state, radius: action.payload };
    case 'MIN_BEDROOMS':
      return {
        ...state,
        bedrooms: {
          ...state.bedrooms,
          min: action.payload,
        },
      };
    case 'MAX_BEDROOMS':
      return {
        ...state,
        bedrooms: {
          ...state.bedrooms,
          max: action.payload,
        },
      };
    case 'MIN_PRICE':
      return {
        ...state,
        priceRange: {
          ...state.priceRange,
          min: action.payload,
        },
      };
    case 'MAX_PRICE':
      return {
        ...state,
        priceRange: {
          ...state.priceRange,
          max: action.payload,
        },
      };
    case 'SET_SELECTED_PROPERTY_TYPE':
      return {
        ...state,
        selectedPropertyTypes: [
          ...state.selectedPropertyTypes,
          ...[action.payload],
        ],
      };
    case 'REMOVE_SELECTED_PROPERTY_TYPE':
      return {
        ...state,
        selectedPropertyTypes: state.selectedPropertyTypes.filter(
          ({ value }) => !value.match(action.payload.value)
        ),
      };
    case 'SET_PROPERTY_TYPE':
      return {
        ...state,
        propertyType: {
          ...state.propertyType,
          ...action.payload,
        },
      };
    case 'SET_PROPERTIES':
      return {
        ...state,
        originalData: action.payload,
      };
    case 'SET_CONTENT_DATA':
      return {
        ...state,
        contentData: action.payload,
      };
    case 'SET_COORDINATES':
      return {
        ...state,
        location: action.payload,
      };
    case 'SET_SEARCHED_LOCATION':
      return {
        ...state,
        searchedLocation: action.payload,
      };
    case 'SET_QUERY':
      return {
        ...state,
        query: action.payload,
      };
    case 'SET_SEE_ALL_LOCATIONS':
      return {
        ...state,
        seeAllLocations: action.payload,
        query: '',
        radius: 20,
      };
    case 'SET_IS_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'RESET_BEDROOMS':
      return {
        ...state,
        bedrooms: {
          min: null,
          max: null,
        },
      };
    case 'RESET_PRICE':
      return {
        ...state,
        priceRange: {
          min: null,
          max: null,
        },
      };
    case 'RESET_PROPERTY_TYPES':
      return {
        ...state,
        selectedPropertyTypes: [],
      };
    case 'SET_ACTIVE_FILTER':
      return {
        ...state,
        activeFilter: action.payload,
      };
    case 'RESET_FILTERS':
      return {
        ...state,
        priceRange: {
          min: null,
          max: null,
        },
        bedrooms: {
          min: null,
          max: null,
        },
        selectedPropertyTypes: [],
      };
    case 'TOGGLE_SEARCH_INPUT':
      return {
        ...state,
        toggleSearchInput: action.payload,
      };
    case 'SET_SHOW_MAP':
      return {
        ...state,
        showMap: action.payload,
      };
    case 'SET_SHOW_FILTER_MENU':
      return {
        ...state,
        showFilterMenu: action.payload,
      };
    case 'INITIAL_LOCATION':
      return {
        ...state,
        initialLocation: action.payload,
      };

    case 'SET_FALLBACK_RADIUS':
      return {
        ...state,
        fallbackRadius: action.payload,
      };
    case 'PLOT_COUNT':
      return {
        ...state,
        plotCount: action.payload,
      };
    case 'DEVELOPMENT_COUNT':
      return {
        ...state,
        developmentCount: action.payload,
      };
    case 'INITIAL_RADIUS':
      return {
        ...state,
        initialRadius: action.payload,
      };
    default:
      return state;
  }
};
