/* eslint-disable react/prop-types */
import React from 'react';
import {
  Wrap,
  Inner,
  Title,
  TagLine,
  Link,
  ImageWrap,
  Image,
  SpecialOffers,
  ImageLink,
} from './HouseCard.styles.js';

import ContentLabel from '../content-label/ContentLabel';

const HouseCard = ({
  name,
  bedrooms,
  price,
  priceLocalised,
  url,
  specialOffer,
  image,
}) => (
  <Wrap>
    <ImageWrap>
      {image ? (
        <ImageLink href={url}>
          <Image src={image.url} alt={image.alt} />
        </ImageLink>
      ) : null}
      {specialOffer ? <SpecialOffers>{specialOffer}</SpecialOffers> : null}
    </ImageWrap>
    <Inner>
      {name ? <Title>{name}</Title> : null}
      {renderTagLine(bedrooms, price, priceLocalised) ? (
        <TagLine>
          {bedroomContent(bedrooms)} {priceLocalised}
        </TagLine>
      ) : null}
      {url ? (
        <Link
          href={url}
          onClick={() => {
            window.dataLayer.push({
              event: 'ViewPlot',
              plotNumberName: name,
            });
          }}
        >
          <ContentLabel name="viewHomeLabel" />
        </Link>
      ) : null}
    </Inner>
  </Wrap>
);

const renderTagLine = (bedrooms, price, priceLocalised) => {
  const bedroomsRender = bedrooms !== 0;
  const priceRender = price !== 0 || priceLocalised === 'POA';

  return priceRender || bedroomsRender;
};

const bedroomContent = (bedrooms) => {
  return bedrooms !== 0 ? (
    <>
      {bedrooms} <ContentLabel name="bedroomsLabel" />{' '}
    </>
  ) : null;
};

export default HouseCard;
