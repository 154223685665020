import styled from 'styled-components';
import { minBp } from '../../../lib/theme';

export const DesktopFilterComponent = styled.div`
  ${({ isSticky }) => `
    display: none;

    @media ${minBp('tabletLarge')} {
      display: flex;
      background-color: #f2f2f2;
      padding: 30px 50px;
      z-index: 9;
      position: ${isSticky ? 'fixed' : 'relative'};
      width: ${isSticky ? '100%' : 'auto'};
      top: ${isSticky ? '0' : 'auto'};
    }
  `};
`;

export const Wrap = styled.div`
  display: flex;
  width: 100%;
`;

export const Inner = styled.div`
  display: flex;
  flex: 1;
  position: relative;
`;

export const InputWrap = styled.div`
  display: flex;
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }
`;

export const Input = styled.input`
  box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.09);
  padding: 12px 30px 12px 10px;
  border: none;
  font-family: 'Lato', serif;
  color: #153372;
  min-width: 100px;
  font-size: 16px;

  &::-ms-clear {
    display: none;
  }

  @media ${minBp('desktop')} {
    min-width: 350px;
  }

  @media ${minBp('additional.desktopLarge')} {
    min-width: 400px;
  }

  &:focus {
    outline: none;
  }
`;

export const InputIcon = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
`;

export const InputButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const ButtonWrap = styled.div`
  position: relative;
  margin-left: 20px;
`;

export const FilterButton = styled.button`
  ${({ hasFilterSelected, isRadiusSelected }) => `
      background: ${hasFilterSelected || isRadiusSelected ? '#0c2c64' : '#fff'};
      border: none;
      box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, 0.09);
      color: ${hasFilterSelected || isRadiusSelected ? '#fff' : '#13346e'};
      font-size: 16px;
      position: relative;
      cursor: pointer;
      flex: 1;
      align-items: center;
      display: flex;
      justify-content: center;
      height: 100%;

      &:focus {
        outline: none;
      }

      @media ${minBp('desktop')} {
        min-width: 200px;
      }
  `};
`;

export const Icon = styled.img`
  ${({ hasFilterSelected, isRadiusDropdown }) => `
    display: ${hasFilterSelected ? 'none' : 'block'};
    margin-right: 10px;
    position: ${isRadiusDropdown ? 'absolute' : 'relative'};
    right: ${isRadiusDropdown ? '20px' : 'auto'};
    
    @media ${minBp('desktop')} {
      margin-right: 15px;
    }   
  `};
`;

export const RadiusDropdownText = styled.span``;

export const Content = styled.div`
  position: absolute;
  top: 120%;
  left: 0;
  width: 250px;
  box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  padding: 20px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: default;
`;

export const DropdownWrap = styled.div`
  flex: 1;
  position: relative;
  display: flex;

  &:hover {
    ${Content} {
      display: block;
    }
  }
`;

export const DropdownButton = styled.div`
  background-color: #fff;
  color: #13346e;
  padding: 16px;
  font-size: 16px;
  border: none;
`;

export const FilterWrap = styled.div`
  display: flex;

  ${ButtonWrap}:first-of-type {
    margin-right: 50px;
  }

  @media ${minBp('desktop')} {
    min-width: 200px;
  }

  @media ${minBp('additional.desktopLarge')} {
    ${ButtonWrap}:first-of-type {
      margin-right: 150px;
    }
  }
`;

export const ButtonTitle = styled.span``;

export const ResultsWrap = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50px;
  left: 15px;
  width: calc(100% - 15px);
  background-color: #fff;
  box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.09);
  overflow-y: scroll;
  max-height: 600px;
`;

export const Result = styled.div`
  padding: 15px 15px 15px 30px;
  color: #1f3b80;
  font-family: 'Lato', serif;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
`;

export const ResultsInner = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
  position: relative;

  &:last-of-type {
    border-bottom: 0;
  }
`;

export const ResultIcon = styled.img`
  max-width: 9px;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

export const ActionWrap = styled.div`
  width: 100%;
  display: block;

  &:before {
    content: '';
    width: 100%;
    height: 1px;
    background-color: #e9e9e9;
    display: block;
    position: absolute;
    left: 0;
  }
`;

export const ActionInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
  width: 100%;
`;

export const ClearBtn = styled.a`
  background: transparent;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const ClearBtnText = styled.span`
  text-decoration: underline;
  font-family: 'Lato', serif;
  color: #13346e;
  font-size: 13px;
`;

export const ApplyBtn = styled.a`
  background: #0c2d60;
  padding: 6px 30px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const ApplyBtnText = styled.span`
  font-family: 'Lato', serif;
  color: #fff;
  font-size: 13px;
`;
