import styled from 'styled-components';
import tw from 'tailwind.macro';
import { minBp, maxBp } from '../../lib/theme';

export const MapContainer = styled.div`
  ${({ showMap }) => `  
  display: ${showMap ? 'block' : 'none'};
  position: ${showMap ? 'fixed' : 'relative'};
  width: ${showMap ? '100%' : 'auto'};
  top: ${showMap ? '80px' : 'auto'};
  
  @media ${minBp('tabletLarge')} {
    display: block;
    flex: 1;
    height: 616px;
    top: 150px;
    position: sticky;
  }

  /* //? Override google map styles */
  .gm-style .gm-style-iw-t::after {
    content: none;
  }

  .gm-style-iw.gm-style-iw-c {
    border-radius: 0;
    padding: 0;

    @media ${maxBp('desktop')} { 
      max-width: 300px !important;
    }
  }

  .gm-style-iw-d {
    overflow: hidden !important;
  }

  .gm-style-iw .gm-style-iw-c {
    @media ${maxBp('tabletLarge')} {
      max-width: none !important;
    }
  }
  `};
`;

export const MapWrap = styled.div`
  ${({ showMap }) => `
    width: ${showMap ? '100%' : 'auto'};
    position: fixed;
    height: 100%;

    @media ${minBp('tabletLarge')} {
      position: absolute;
      width: 100%;
    }
  `};
`;

export const ContainerElement = styled.div`
  height: calc(100% - 160px);

  @media ${minBp('tabletLarge')} {
    height: 450px;
  }

  @media ${minBp('additional.desktopLarge')} {
    height: 500px;
  }
`;

export const MapComponent = styled.section`
  ${tw``};
`;

export const IconContainer = styled.div`
  border: 1px solid rgb(224, 224, 224);
  display: flex;
  justify-content: space-around;
  padding: 15px;
  background-color: #fff;
`;

export const IconInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
`;

export const Icon = styled.img`
  margin-bottom: 0;
  height: 100%;
  max-height: 30px;
`;

export const IconText = styled.p`
  font-size: 12px;
  color: rgb(114, 114, 114);
  margin-bottom: 0;
  margin-top: 5px;
`;

export const ExitMap = styled.button`
  background-color: #fff;
  font-size: 14px;
  color: #1d3c7c;
  padding: 5px;
  font-family: 'Lato', serif;
  position: absolute;
  border: none;
  top: 12px;
  left: 12px;

  &:focus {
    outline: none;
  }

  @media ${minBp('tabletLarge')} {
    display: none;
  }
`;
