/* eslint-disable react/prop-types */
import React from 'react';
import { Wrap, Button, Text } from './LoadMore.styles.js';

import ContentLabel from '../content-label/ContentLabel';

const LoadMoreBtn = ({ handleLoadMore, isVisible }) => (
  <Wrap>
    <Button onClick={handleLoadMore} isVisible={isVisible}>
      <Text isVisible={isVisible}>
        {isVisible ? (
          <ContentLabel name="loadMoreLabel" />
        ) : (
          <ContentLabel name="noMoreHomesAvailableLabel" />
        )}
      </Text>
    </Button>
  </Wrap>
);

export default LoadMoreBtn;
