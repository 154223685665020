/* eslint-disable react/prop-types */
import React, { useState, useContext } from 'react';
import DevelopmentPlotGroup from '../development-plot-group/DevelopmentPlotGroup.jsx';
import LoadMoreBtn from '../load-more/LoadMore';
import { Context } from '../../pages/search-and-results/search-and-results';
import { NoMoreHomesText } from './ResultsList.styles.js';
import ContentLabel from '../content-label/ContentLabel.jsx';

const ResultsList = ({
  showAllHomes,
  setDevelopmentHover,
  setDevelopmentHoverLocation,
  goalEndpoint,
}) => {
  const houseContext = useContext(Context);
  const [loadMoreLimit, setLoadMoreLimit] = useState(5);

  const { originalData } = houseContext.state;

  function handleLoadMore() {
    setLoadMoreLimit(loadMoreLimit + 5);
  }

  const developmentsToShow = originalData.filter(
    (development) =>
      development.status === 'Coming Soon' || development.status === 'Open'
  );

  return (
    <>
      {developmentsToShow.slice(0, loadMoreLimit).map((development, i) => (
        <DevelopmentPlotGroup
          key={i}
          development={development}
          goalEndpoint={goalEndpoint}
          showAllHomes={showAllHomes}
          setDevelopmentHover={setDevelopmentHover}
          setDevelopmentHoverLocation={setDevelopmentHoverLocation}
        />
      ))}

      {developmentsToShow.length > loadMoreLimit ? (
        <LoadMoreBtn
          loadMoreLimit={loadMoreLimit}
          handleLoadMore={handleLoadMore}
          isVisible={originalData.length > loadMoreLimit}
        />
      ) : (
        <NoMoreHomesText>
          <ContentLabel name='noMoreHomesAvailableLabel' />
        </NoMoreHomesText>
      )}
    </>
  );
};

export default ResultsList;
