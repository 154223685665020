import styled from 'styled-components';
import { minBp } from '../../lib/theme';

export const Wrap = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 0 20px;
  width: 100%;

  @media ${minBp('tabletLarge')} {
    margin: 40px 0;
    flex-direction: column;
  }
`;

export const Button = styled.button`
  ${({ isVisible }) => `
    background-color: ${isVisible ? '#0c2c64' : '#fff'};
    height: 48px;
    padding: 10px 20px;
    cursor: ${isVisible ? 'pointer' : 'auto'};
    width: calc(100% - 40px);
    margin: 0 auto;
    cursor: ${isVisible ? '#fff' : '#0c2c64'};
    pointer-events: ${isVisible ? 'auto' : 'none'};
    border: none;
    align-self: center;
    
    @media ${minBp('tabletLarge')} {
      width: auto;
      min-width: 300px;
    }

    &:focus {
      outline: none;
    }
  `};
`;

export const Text = styled.span`
  ${({ isVisible }) => `
    color: ${isVisible ? '#fff' : '#0c2c64'};
    font-size: 18px;
    font-family: 'Lato', serif;
  `}
`;
