import styled from 'styled-components';
import { minBp } from '../../lib/theme';

export const ResultsSectionComponent = styled.section`
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  @media ${minBp('tabletLarge')} {
    flex: 2;
    height: 100%;
    margin-right: 60px;
  }
`;
