/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import {
  ResultsIntroComponent,
  Wrap,
  Title,
  ActionWrap,
  Action,
  Image,
  Checkbox,
  Label,
  CheckMark
} from './ResultsIntro.styles.js';

import { Context } from '../../pages/search-and-results/search-and-results';

import CurrentDevelopmentMarker from '../../assets/icons/CurrentDevelopmentMarker.svg';

import ContentLabel from '../content-label/ContentLabel';

const ResultsIntro = ({
  setShowAllHomes,
  showAllHomes,
  initialLocation,
  noResults
}) => {
  const introContext = useContext(Context);

  const selectedRadius = introContext.state.radius;

  const {
    searchedLocation,
    seeAllLocations,
    fallbackRadius,
    selectedPropertyTypes,
    originalData,
    developmentCount
  } = introContext.state;

  function calculateAvailableHomes() {
    if (noResults) {
      return 0;
    }

    if (originalData) {
      return originalData.reduce((a, b) => {
        return (b.status == "Open" && b.homes) ? (a += b.homes.length) : (a += 0);
      }, 0);
    }
  }

  const selectedPropertyTypeValues = selectedPropertyTypes.map(property => {
    return property.value;
  });

  const handleClick = () => {
    setShowAllHomes(!showAllHomes);

    if (showAllHomes) {
      window.dataLayer.push({
        event: 'FilterHomesHide',
        searchQuery: searchedLocation || initialLocation,
        filterPropertyType: selectedPropertyTypeValues,
        countDevelopments: developmentCount,
        filterShow: false
      });
    } else {
      window.dataLayer.push({
        event: 'FilterHomesShow',
        searchQuery: searchedLocation || initialLocation,
        filterPropertyType: selectedPropertyTypeValues,
        countDevelopments: developmentCount,
        filterShow: true
      });
    }
  };

  let radius;

  if (introContext.state.location) {
    radius = introContext.state.location.radius;
  }

  const {
    homesWithinRadiusLabel,
    homesWithinUKLabel
  } = introContext.state.contentData.labels;

  const mileageOutput = () => {
    if (typeof radius === 'undefined') {
      return selectedRadius || fallbackRadius == null ? "0" : fallbackRadius;
    }
    return selectedRadius || radius;
  };

  function generateIntroString() {
    if (
      (seeAllLocations && homesWithinUKLabel) ||
      (homesWithinUKLabel && initialLocation === '' && !searchedLocation)
    ) {
      return homesWithinUKLabel
        .replace('{0}', calculateAvailableHomes())
        .replace('{1}', mileageOutput());
    }

    if (homesWithinRadiusLabel) {
      return homesWithinRadiusLabel
        .replace('{0}', calculateAvailableHomes())
        .replace('{1}', mileageOutput())
        .replace('{2}', searchedLocation || initialLocation);
    }
  }

  return (
    <ResultsIntroComponent
      noResults={noResults}
      hasResults={calculateAvailableHomes() > 0}
    >
      <Wrap>
        <Title hasResults={originalData}>{generateIntroString()}</Title>
        {originalData ? (
          <ActionWrap>
            <Action
              onClick={() => {
                introContext.dispatch({
                  type: 'SET_SHOW_MAP',
                  payload: true
                });
                window.dataLayer.push({
                  event: 'ShowMap',
                  filterPropertyType: selectedPropertyTypeValues
                });
              }}
            >
              <Image src={CurrentDevelopmentMarker} />
              <ContentLabel name="viewOnMapLabel" />
            </Action>
            <Label>
              <Checkbox
                onChange={handleClick}
                checked={showAllHomes}
                type="checkbox"
              />
              <CheckMark></CheckMark>
              <ContentLabel name="showAllHomesLabel" />
            </Label>
          </ActionWrap>
        ) : null}
      </Wrap>
    </ResultsIntroComponent>
  );
};

export default ResultsIntro;
