/* eslint-disable react/prop-types */
import React, { useRef, useEffect, useContext } from 'react';
import {
  FilterButton,
  Content,
  Icon,
  ActionWrap,
  ActionInner,
  ClearBtn,
  ClearBtnText,
  ApplyBtn,
  ApplyBtnText,
  ButtonWrap
} from '../filters/Desktop/DesktopFilters.styles';

import { formatCurrency } from '../../utility/helpers';
import ChevronDownIcon from '../../assets/icons/ChevronDown.svg';

import FilterSelector from '../filter-selector/FilterSelector';
import FilterDropdown from '../filter-dropdown/FilterDropdown';
import ContentLabel from '../content-label/ContentLabel';

import { Context } from '../../pages/search-and-results/search-and-results.js';

const FilterGroup = ({
  index,
  filter,
  filterTitle,
  handleSaveFilter,
  fetchProperties,
  desktopFilters
}) => {
  const contentRef = useRef(null);
  const buttonRef = useRef(null);
  const filterGroupContext = useContext(Context);

  const { labels } = filterGroupContext.state.contentData;
  const {
    selectedPropertyTypes,
    radius,
    bedrooms,
    priceRange,
    activeFilter,
    developmentCount,
    plotCount,
    initialRadius
  } = filterGroupContext.state;

  const priceID = '1518fc9b-a6ad-4356-b165-f48ded104ac7';
  const bedroomsID = 'fe0a84dc-da47-464e-9920-5ddefca911c1';
  const propertyTypeID = 'b119da3d-3576-4829-9fff-67adf0ba12fb';
  const radiusID = 'f67a0762-4ae8-46a8-a8b3-e43a9119a5c7';

  let minValue;
  let maxValue;

  if (filter.id === priceID) {
    minValue = filterGroupContext.state.priceRange.min;
    maxValue = filterGroupContext.state.priceRange.max;
  }

  if (filter.id === bedroomsID) {
    minValue = filterGroupContext.state.bedrooms.min;
    maxValue = filterGroupContext.state.bedrooms.max;
  }

  const singleSelection = selectedPropertyTypes.map(obj => {
    return Object.values(obj);
  });

  const selectedPropertyTypeValues = selectedPropertyTypes.map(property => {
    return property.value;
  });

  function getActiveFilterButtonText() {
    if (desktopFilters) {
      if (filter.id === propertyTypeID) {
        switch (selectedPropertyTypes.length) {
          case 0:
            return filter.title;
          case 1:
            return singleSelection.toString();
          case 2:
            return `multipleLabel`;
          default:
            return `multipleLabel`;
        }
      }

      if (filter.id === radiusID) {
        if (radius) {
          return filterTitle.replace('{0}', radius);
        }
      }

      if (!minValue && !maxValue) {
        return filterTitle;
      }

      if (minValue && maxValue) {
        if (filter.id === priceID) {
          return `${formatCurrency(minValue)} - ${formatCurrency(maxValue)}`;
        }

        if (filter.id === bedroomsID) {
          return `${minValue} - ${maxValue} ${labels.bedroomsLabel ||
            labels.bedroomsLabel}`;
        }
      }

      if (minValue && !maxValue) {
        if (filter.id === priceID) {
          return `${labels.minLabel || ''} - ${formatCurrency(minValue)}`;
        }

        if (filter.id === bedroomsID) {
          return `${labels.minLabel || ''} - ${minValue}`;
        }
      }

      if (!minValue && maxValue) {
        if (filter.id === priceID) {
          return `${formatCurrency(maxValue)} - ${labels.maxLabel || ''}`;
        }

        if (filter.id === bedroomsID) {
          return `${maxValue} - ${labels.maxLabel || ''}`;
        }
      }
    }
  }

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        contentRef.current &&
        !contentRef.current.contains(event.target) &&
        event.target !== buttonRef.current
      ) {
        filterGroupContext.dispatch({
          type: 'SET_ACTIVE_FILTER',
          payload: null
        });
      }
    };

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const hasFilterSelected = getActiveFilterButtonText() !== filterTitle;

  const handleRadioChange = () => {
    if (desktopFilters) {
      window.dataLayer.push({
        event: 'FilterApply',
        filterSearchRadius: `${radius || initialRadius} miles`,
        filterPriceRangeMin: priceRange.min,
        filterPriceRangeMax: priceRange.max,
        filterBedroomsMin: bedrooms.min,
        filterBedroomsMax: bedrooms.max,
        filterPropertyType: selectedPropertyTypeValues,
        countDevelopments: developmentCount,
        countPlots: plotCount,
        filterName: 'Radius'
      });
    }
  }

  return (
    <ButtonWrap>
      <FilterButton
        onClick={() => {
          filterGroupContext.dispatch({
            type: 'SET_ACTIVE_FILTER',
            payload: index
          });

          window.dataLayer.push({
            event: 'FilterSelect',
            filterSearchRadius: `${radius || initialRadius} miles`,
            filterPriceRangeMin: priceRange.min,
            filterPriceRangeMax: priceRange.max,
            filterBedroomsMin: bedrooms.min,
            filterBedroomsMax: bedrooms.max,
            filterPropertyType: selectedPropertyTypeValues,
            countDevelopments: developmentCount,
            countPlots: plotCount,
            filterName: 'Radius'
          });
        }}
        hasFilterSelected={hasFilterSelected}
        isRadiusSelected={filter.id === radiusID && radius}
        ref={buttonRef}
      >
        {filter.icon ? (
          <Icon
            hasFilterSelected={
              hasFilterSelected || (radius && filter.id === radiusID)
            }
            src={
              desktopFilters && filter.id === radiusID
                ? ChevronDownIcon
                : filter.icon
            }
            isRadiusDropdown={desktopFilters && filter.id === radiusID}
          />
        ) : null}
        {filter.title ? (
          <ContentLabel name={getActiveFilterButtonText()} />
        ) : null}
      </FilterButton>
      {activeFilter === index ? (
        <Content ref={contentRef}>
          {filter.type === 'slider' ? (
            <FilterDropdown
              values={filter.values}
              id={filter.id}
              minimumLabel={filter.minimumLabel}
              maximumLabel={filter.maximumLabel}
              icon={filter.icon}
              handleSaveFilter={handleSaveFilter}
              filterTitle={filter.title}
              desktopFilters
              selectedPropertyTypeValues={selectedPropertyTypeValues}
            />
          ) : (
            <FilterSelector
              title={filter.title}
              id={filter.id}
              type={filter.type}
              values={filter.values}
              desktopFilters
              radioChange={() => handleRadioChange()}
            />
          )}
          {filter.id === propertyTypeID ? (
            <ActionWrap>
              <ActionInner>
                <ClearBtn
                  role="button"
                  onClick={() => {
                    filterGroupContext.dispatch({
                      type: 'RESET_PROPERTY_TYPES'
                    });

                    window.dataLayer.push({
                      event: 'FilterClear',
                      filterSearchRadius: `${radius || initialRadius} miles`,
                      filterPriceRangeMin: priceRange.min,
                      filterPriceRangeMax: priceRange.max,
                      filterBedroomsMin: bedrooms.min,
                      filterBedroomsMax: bedrooms.max,
                      filterPropertyType: selectedPropertyTypeValues,
                      countDevelopments: developmentCount,
                      countPlots: plotCount,
                      filterName: filterTitle
                    });
                  }}
                >
                  <ClearBtnText>
                    <ContentLabel name="clearLabel" />
                  </ClearBtnText>
                </ClearBtn>
                <ApplyBtn
                  role="button"
                  onClick={() => {
                    fetchProperties();
                    window.dataLayer.push({
                      event: 'FilterApply',
                      filterSearchRadius: `${radius || initialRadius} miles`,
                      filterPriceRangeMin: priceRange.min,
                      filterPriceRangeMax: priceRange.max,
                      filterBedroomsMin: bedrooms.min,
                      filterBedroomsMax: bedrooms.max,
                      filterPropertyType: selectedPropertyTypeValues,
                      countDevelopments: developmentCount,
                      countPlots: plotCount,
                      filterName: filterTitle
                    });
                  }}
                >
                  <ApplyBtnText>
                    <ContentLabel name="saveLabel" />
                  </ApplyBtnText>
                </ApplyBtn>
              </ActionInner>
            </ActionWrap>
          ) : null}
        </Content>
      ) : null}
    </ButtonWrap>
  );
};
export default FilterGroup;
