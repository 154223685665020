/* eslint-disable react/prop-types */
import React, { useState, useContext, useEffect } from 'react';
import { ResultsSectionComponent } from './ResultsSection.styles.js';
import ResultsIntro from '../results-intro/ResultsIntro.jsx';
import ResultsList from '../results-list/ResultsList.jsx';

import { Context } from '../../pages/search-and-results/search-and-results';

const ResultsSection = ({
  setDevelopmentHover,
  setDevelopmentHoverLocation,
  goalEndpoint
}) => {
  const resultsContext = useContext(Context);
  const [showAllHomes, setShowAllHomes] = useState(
    resultsContext.state.contentData.showAllHomes
  );

  const {
    searchedLocation,
    radius,
    priceRange,
    bedrooms,
    selectedPropertyTypes,
    developmentCount,
    plotCount,
    originalData,
    initialRadius,
    initialLocation
  } = resultsContext.state;

  const selectedPropertyTypeValues = selectedPropertyTypes.map(property => {
    return property.value;
  });

  useEffect(() => {
    if (
      (searchedLocation || initialLocation) &&
      typeof (radius || initialRadius) === 'number'
    ) {
      try {
        window.dataLayer.push({
          event: 'SearchResults',
          searchQuery: searchedLocation || initialLocation,
          filterSearchRadius: `${radius || initialRadius} miles`,
          filterPriceRangeMin: priceRange.min,
          filterPriceRangeMax: priceRange.max,
          filterBedroomsMin: bedrooms.min,
          filterBedroomsMax: bedrooms.max,
          filterPropertyType: selectedPropertyTypeValues,
          countDevelopments: developmentCount,
          countPlots: plotCount
        });
      }
      catch (error) {
        console.error(error)
      }
      
    }
  }, [originalData]);

  return (
    <ResultsSectionComponent>
      <ResultsIntro
        showAllHomes={showAllHomes}
        setShowAllHomes={setShowAllHomes}
        initialLocation={initialLocation}
      />

      <ResultsList
        goalEndpoint={goalEndpoint}
        showAllHomes={showAllHomes}
        setDevelopmentHover={setDevelopmentHover}
        setDevelopmentHoverLocation={setDevelopmentHoverLocation}
      />
    </ResultsSectionComponent>
  );
};

export default ResultsSection;
