import { resolve } from '../utility/helpers';

const theme = {
  colors: {
    body: '#585a5f',
    heading: '#000000',
    primary: '#cd192d',
    buttons: {
      default: '#000000',
      primary: '#cd192d',
      white: '#ffffff'
    }
  },
  breakpoints: {
    mobileEnd: 480,
    tablet: 768,
    tabletLarge: 1024,
    desktop: 1260,

    additional: {
      mobile: 375,
      desktopLarge: 1600
    }
  }
};

export default theme;

export const minBp = breakpoint =>
  `(min-width: ${resolve(theme.breakpoints, breakpoint)}px)`;
export const maxBp = breakpoint =>
  `(max-width: ${theme.breakpoints[breakpoint] - 1}px)`;
