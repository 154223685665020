import { useEffect, useState } from 'react';

export const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    width: state.selectProps.width,
    color: '#1d3c7c',
    fontSize: '12px',
    textAlign: 'left',
    cursor: 'pointer',
  }),

  control: (
    _,
    {
      selectProps: { width, display, backgroundColor, border, fontSize, color },
    }
  ) => ({
    width: width,
    display: display,
    backgroundColor: backgroundColor,
    border: border,
    fontSize: fontSize,
    color: color,
    cursor: 'pointer',
  }),

  container: (base) => ({
    ...base,
    flex: 1,
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    const cursor = 'pointer';

    return { ...provided, opacity, transition, cursor };
  },
  option: (styles, state) => ({
    ...styles,
    cursor: 'pointer',
  }),
};

export const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
};

export const resolve = (obj, path, defaultValue = null) => {
  if (!obj || !path) {
    return defaultValue;
  }

  const parts = path.split('.');

  return parts.reduce((newObj, part) => {
    if (!newObj) {
      return defaultValue;
    }

    if (newObj[part]) {
      newObj = newObj[part]; // eslint-disable-line
      return newObj;
    }

    return defaultValue;
  }, obj);
};

export function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

export const getHostname = () =>
  process.env.REACT_APP_HOSTNAME || window.location.origin;

export const getSearchRadius = (radius, initialRadius) => {
  let defaultRadius = 40;

  //Only present in County Pages
  if (document.getElementById('introComponent')) {
    defaultRadius = 50;
  }
  return parseInt(radius || initialRadius, 10) || defaultRadius;
};

export const filterByCounty = (responseData) => {
  const introComponent = document.getElementById('introComponent');
  if (introComponent) {
    const pageName = introComponent
      ?.getAttribute('data-page-name')
      ?.trim()
      ?.toLowerCase();
    const filteredDevelopments = responseData.developments.filter(
      (development) => {
        const developmentCounty = development?.county?.trim()?.toLowerCase();
        return developmentCounty === pageName;
      }
    );
    responseData.developments = filteredDevelopments;
  }
};

export const filterByTown = (responseData) => {
  const introComponent = document.getElementById('introComponent');
  if (introComponent) {
    const pageName = introComponent
      ?.getAttribute('data-page-name')
      ?.trim()
      ?.toLowerCase();
    const filteredDevelopments = responseData.developments.filter(
      (development) => {
        const developmentCounty = development?.town?.trim()?.toLowerCase();
        return developmentCounty === pageName;
      }
    );
    responseData.developments = filteredDevelopments;
  }
};

export const filterByTownOrCounty = (data) => {
  const introComponent = document.getElementById('introComponent');
  const pageType = introComponent
    ?.getAttribute('data-page-type')
    ?.trim()
    ?.toLowerCase();
  pageType === 'location page' ? filterByTown(data) : filterByCounty(data);
}