/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import {
  Wrap,
  TitleWrap,
  Icon,
  Title,
  DropdownOuter,
  DropdownWrap,
  ActionWrap,
  ActionInner,
  ClearBtn,
  ClearBtnText,
  ApplyBtn,
  ApplyBtnText
} from './FilterDropdown.styles.js';

import { customStyles } from '../../utility/helpers';
import { Context } from '../../pages/search-and-results/search-and-results';

import ContentLabel from '../content-label/ContentLabel';

import Select from 'react-select';

const FilterDropdown = ({
  values,
  icon,
  minimumLabel,
  maximumLabel,
  width,
  handleSaveFilter,
  id,
  mobileFilters,
  filterTitle,
  selectedPropertyTypeValues
}) => {
  const dropdownContext = useContext(Context);

  // ID's of individual filters, these will never change and are therefore safe to check against.
  const priceID = '1518fc9b-a6ad-4356-b165-f48ded104ac7';
  const bedroomsID = 'fe0a84dc-da47-464e-9920-5ddefca911c1';

  const { labels } = dropdownContext.state.contentData;

  const {
    bedrooms,
    priceRange,
    radius,
    developmentCount,
    plotCount,
    initialRadius
  } = dropdownContext.state;

  const handleClearFilter = id => {
    if (id === bedroomsID) {
      dropdownContext.dispatch({
        type: 'RESET_BEDROOMS'
      });
    }

    if (id === priceID) {
      dropdownContext.dispatch({
        type: 'RESET_PRICE'
      });
    }
  };

  return (
    <Wrap>
      {minimumLabel ? (
        <TitleWrap>
          <Icon src={icon} />
          <Title>{minimumLabel}</Title>
        </TitleWrap>
      ) : null}
      <DropdownOuter>
        <DropdownWrap>
          <>
            {id === bedroomsID ? (
              <Select
                styles={customStyles}
                width={width || '100%'}
                display="flex"
                backgroundColor="white"
                fontSize="12px"
                border="1px solid #e8e8e8"
                options={values}
                color="#1d3c7c"
                components={{
                  IndicatorSeparator: () => null
                }}
                placeholder={labels.selectLabel}
                onChange={event => {
                  dropdownContext.dispatch({
                    type: 'MIN_BEDROOMS',
                    payload: parseInt(event.value)
                  });
                }}
                value={
                  dropdownContext.state.bedrooms.min
                    ? { label: dropdownContext.state.bedrooms.min }
                    : null
                }
                isSearchable={mobileFilters ? false : true}
              />
            ) : null}

            {id === priceID ? (
              <Select
                styles={customStyles}
                width={width || '100%'}
                display="flex"
                backgroundColor="white"
                fontSize="12px"
                border="1px solid #e8e8e8"
                options={values}
                color="#1d3c7c"
                components={{
                  IndicatorSeparator: () => null
                }}
                onChange={event => {
                  dropdownContext.dispatch({
                    type: 'MIN_PRICE',
                    payload: parseInt(event.value)
                  });
                }}
                value={
                  dropdownContext.state.priceRange.min
                    ? { label: dropdownContext.state.priceRange.min }
                    : null
                }
                isSearchable={mobileFilters ? false : true}
              />
            ) : null}
          </>
        </DropdownWrap>
        {maximumLabel ? (
          <TitleWrap>
            <Icon src={icon} />
            <Title>{maximumLabel}</Title>
          </TitleWrap>
        ) : null}
        <DropdownWrap>
          <>
            {id === priceID ? (
              <Select
                styles={customStyles}
                width={width || '100%'}
                display="flex"
                fontSize="12px"
                backgroundColor="white"
                options={values}
                border="1px solid #e8e8e8"
                color="#1d3c7c"
                components={{
                  IndicatorSeparator: () => null
                }}
                onChange={event => {
                  dropdownContext.dispatch({
                    type: 'MAX_PRICE',
                    payload: parseInt(event.value)
                  });
                }}
                value={
                  dropdownContext.state.priceRange.max
                    ? { label: dropdownContext.state.priceRange.max }
                    : null
                }
                isSearchable={mobileFilters ? false : true}
              />
            ) : null}

            {id === bedroomsID ? (
              <Select
                styles={customStyles}
                width={width || '100%'}
                display="flex"
                fontSize="12px"
                backgroundColor="white"
                options={values}
                border="1px solid #e8e8e8"
                color="#1d3c7c"
                components={{
                  IndicatorSeparator: () => null
                }}
                onChange={event => {
                  dropdownContext.dispatch({
                    type: 'MAX_BEDROOMS',
                    payload: parseInt(event.value)
                  });
                }}
                value={
                  dropdownContext.state.bedrooms.max
                    ? { label: dropdownContext.state.bedrooms.max }
                    : null
                }
                isSearchable={mobileFilters ? false : true}
              />
            ) : null}
          </>
        </DropdownWrap>
      </DropdownOuter>
      <ActionWrap>
        <ActionInner>
          <ClearBtn
            role="button"
            onClick={() => {
              handleClearFilter(id);
              window.dataLayer.push({
                event: 'FilterClear',
                filterSearchRadius: `${radius || initialRadius} miles`,
                filterPriceRangeMin: priceRange.min,
                filterPriceRangeMax: priceRange.max,
                filterBedroomsMin: bedrooms.min,
                filterBedroomsMax: bedrooms.max,
                filterPropertyType: selectedPropertyTypeValues,
                countDevelopments: developmentCount,
                countPlots: plotCount,
                filterName: filterTitle
              });
            }}
          >
            <ClearBtnText>
              <ContentLabel name="clearLabel" />
            </ClearBtnText>
          </ClearBtn>
          <ApplyBtn
            role="button"
            onClick={() => {
              handleSaveFilter();
              window.dataLayer.push({
                event: 'FilterApply',
                filterSearchRadius: `${radius || initialRadius} miles`,
                filterPriceRangeMin: priceRange.min,
                filterPriceRangeMax: priceRange.max,
                filterBedroomsMin: bedrooms.min,
                filterBedroomsMax: bedrooms.max,
                filterPropertyType: selectedPropertyTypeValues,
                countDevelopments: developmentCount,
                countPlots: plotCount,
                filterName: filterTitle
              });
            }}
          >
            <ApplyBtnText>
              <ContentLabel name="saveLabel" />
            </ApplyBtnText>
          </ApplyBtn>
        </ActionInner>
      </ActionWrap>
    </Wrap>
  );
};

export default FilterDropdown;
