import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../../components/loading-spinner/LoadingSpinner';
import StandaloneWrappedMap from '../map/StandaloneWrappedMap';
import { getHostname, filterByCounty } from '../../utility/helpers';

const StandaloneMap = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [results, setResults] = useState(null);
  const [contentData, setContentData] = useState(null);

  function handleErrors(response) {
    if (!response.ok) {
      setError(true);
      throw Error(response.statusText);
    }
    setError(false);

    return response;
  }

  const globalSearchData = {
    term: '',
    radius: null
  };

  useEffect(() => {
    setIsLoading(true);
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(globalSearchData)
    };
    fetch(`${getHostname()}/api/v1/locations/search`, requestOptions)
      .then(handleErrors)
      .then(response => response.json())
      .then(data => {
        filterByCounty(data);
        setResults(data);
        setIsLoading(false);
      })
      .catch(error => console.log(error));
  }, []);

  useEffect(() => {
    fetch(
      `${getHostname()}/api/v1/locations/search/content/44F8B44C-3D42-4205-9E6A-6757E936BA41`
    )
      .then(response => response.json())
      .then(data => {
        setContentData(data);
      })
      .catch(error => console.log(error));
  }, []);

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <StandaloneWrappedMap
      error={error}
      results={results}
      contentData={contentData}
    />
  );
};

export default StandaloneMap;
