/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { Context } from '../../pages/search-and-results/search-and-results';

const factoryDefault = val => val;

const ContentLabel = ({ name, fallback, factory = factoryDefault }) => {
  const labelsContext = useContext(Context);
  let label;

  if (labelsContext) {
    label = labelsContext.state.contentData.labels;
  } else {
    label = name;
  }

  return <>{factory(label[name] || name || fallback || '')}</>;
};

export default ContentLabel;
