/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { Wrap, Logo, Title, Intro } from './ErrorMessage.styles.js';

import { Context } from '../../pages/search-and-results/search-and-results';
import ContentLabel from '../../components/content-label/ContentLabel';

const ErrorMessage = () => {
  const errorMessageContext = useContext(Context);

  const state = errorMessageContext.state.contentData.noResultsContent;

  return (
    <Wrap>
      <>
        {/* {state.image ? (
            <Logo src={state.image.url} alt={state.image.alt} />
          ) : null} */}

        <Title>Hmmm… Something seems to have gone wrong</Title>
        <Intro>
          Looks like there’s been a bit of a technical error, please try
          searching again.
        </Intro>
      </>
    </Wrap>
  );
};

export default ErrorMessage;
