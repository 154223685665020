/* eslint-disable react/prop-types */
import React, { useRef } from 'react';
import {
  InfoWindowContentComponent,
  Wrap,
  ImgWrap,
  Img,
  Content,
  ViewDevelopments,
  ViewDevelopmentsMobileWrapper,
  ViewDevelopmentsMobile,
  ViewDevelopmentsClose,
  ViewDevelopmentsCloseIcon,
  Title,
  Intro,
  DescriptionWrap,
  Description
} from './InfoWindowContent.styles.js';

import CloseIcon from '../../assets/icons/Close.svg';
import ContentLabel from '../content-label/ContentLabel';

const InfoWindowContent = ({
  selectedLocation,
  isMobile,
  handleSelected,
  contentData,
  currentLocation
}) => {
  const viewDevelopmentsRef = useRef();
  const viewDevelopmentsHeight = viewDevelopmentsRef.current
    ? viewDevelopmentsRef.current.getBoundingClientRect().height
    : null;

  return selectedLocation ? (
    <InfoWindowContentComponent>
      <Wrap>
        <ImgWrap>
          {selectedLocation.image ? (
            <Img
              src={selectedLocation.image.url}
              alt={selectedLocation.image.alt}
              viewDevelopmentsHeight={viewDevelopmentsHeight}
            />
          ) : null}
          {selectedLocation.url && !isMobile ? (
            <ViewDevelopments
              href={selectedLocation.url}
              ref={viewDevelopmentsRef}
              onClick={() => {
                window.dataLayer.push({
                  event: 'MapMarkerDevelopment',
                  searchQuery: currentLocation,
                  developmentName: selectedLocation.name
                });
              }}
            >
              {contentData && contentData.labels.viewDevelopmentLabel ? (
                <ContentLabel name={contentData.labels.viewDevelopmentLabel} />
              ) : null}
              {!contentData ? (
                <ContentLabel name="viewDevelopmentLabel" />
              ) : null}
            </ViewDevelopments>
          ) : null}
        </ImgWrap>
        <Content>
          {selectedLocation.name ? (
            <Title>{selectedLocation.name}</Title>
          ) : null}
          {selectedLocation.distance ? (
            <Intro>
              {selectedLocation.distance.toFixed(1)}{' '}
              {contentData && contentData.labels.milesLabel ? (
                <ContentLabel name={contentData.labels.milesLabel} />
              ) : null}
              {!contentData ? <ContentLabel name="milesLabel" /> : null}
            </Intro>
          ) : null}
          <DescriptionWrap>
            {selectedLocation.location ? (
              <Description>{selectedLocation.location}</Description>
            ) : null}
            {selectedLocation.tagline ? (
              <Description>{selectedLocation.tagline}</Description>
            ) : null}
          </DescriptionWrap>
          {selectedLocation.homes ? (
            <Intro>
              {selectedLocation.homes.length}{' '}
              {contentData && contentData.labels.potentialHomesLabel ? (
                <ContentLabel name={contentData.labels.potentialHomesLabel} />
              ) : null}
              {!contentData ? (
                <ContentLabel name="potentialHomesLabel" />
              ) : null}
            </Intro>
          ) : null}
        </Content>
        {selectedLocation.url && isMobile ? (
          <ViewDevelopmentsMobileWrapper>
            <ViewDevelopmentsClose
              onClick={() => {
                handleSelected(null, null);
                window.dataLayer.push({
                  eventCategory: 'Interaction',
                  eventAction: 'Map Marker - Close',
                  eventLabel: selectedLocation.name,
                  event: 'MapMarkerClose'
                });
              }}
            >
              <ViewDevelopmentsCloseIcon src={CloseIcon} />
            </ViewDevelopmentsClose>
            <ViewDevelopmentsMobile
              href={selectedLocation.url}
              ref={viewDevelopmentsRef}
              onClick={() => {
                window.dataLayer.push({
                  event: 'MapMarkerDevelopment',
                  searchQuery: currentLocation,
                  developmentName: selectedLocation.name
                });
              }}
            >
              {contentData && contentData.labels.viewDevelopmentLabel ? (
                <ContentLabel name={contentData.labels.viewDevelopmentLabel} />
              ) : null}
              {!contentData ? (
                <ContentLabel name="viewDevelopmentLabel" />
              ) : null}
            </ViewDevelopmentsMobile>
          </ViewDevelopmentsMobileWrapper>
        ) : null}
      </Wrap>
    </InfoWindowContentComponent>
  ) : null;
};

export default InfoWindowContent;
