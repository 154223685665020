import styled from 'styled-components';
import { minBp } from '../../lib/theme';

export const ResultsListComponent = styled.section``;

export const NoMoreHomesText = styled.div`
  color: #153372;
  text-align: center;
  width: 100%;
  padding: 10px 20px 30px;
  font-weight: bold;
  font-family: 'Lato', serif;

  @media ${minBp('tablet')} {
    padding: 50px 20px;
  }
`;
