/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { RadioButton, FilterInput, Label } from './FilterRadio.styles.js';
import { Context } from '../../pages/search-and-results/search-and-results.js';

const FilterRadio = ({ name, value, label, radioChange }) => {
  const radioContext = useContext(Context);

  let radius = 40;

  if (radioContext.state.location) {
    radius = radioContext.state.location.radius;
  }

  const selectedRadius = parseInt(radioContext.state.radius);

  function handleRadioChange(e) {
    radioContext.dispatch({
      type: 'SET_RADIUS',
      payload: e.target.value
    });
    radioContext.dispatch({
      type: 'SET_ACTIVE_FILTER',
      payload: null
    });

    if (radioChange) {
      radioChange();
    }
  }

  return (
    <Label>
      <FilterInput
        type="radio"
        name={name}
        value={value}
        onChange={handleRadioChange}
        checked={
          selectedRadius === parseInt(value) || radius === parseInt(value)
        }
      />
      {label}
      <RadioButton />
    </Label>
  );
};

export default FilterRadio;
