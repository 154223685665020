/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import FilterRadio from '../filter-radio/FilterRadio.jsx';
import FilterCheckbox from '../filter-checkbox/FilterCheckbox.jsx';

import { Wrap } from './FilterSelector.styles';

const FilterSelector = ({ type, values, desktopFilters, radioChange }) => {
  let filterType;

  if (type === 'options') {
    filterType = 'checkbox';
  } else {
    filterType = 'radio';
  }

  return (
    <Wrap isCheckbox={filterType === 'checkbox'}>
      {values.map(({ value, label }, k) => (
        <Fragment key={k}>
          {filterType === 'radio' ? (
            <FilterRadio name="Radius" index={k} value={value} label={label} radioChange={radioChange}/>
          ) : (
            <FilterCheckbox
              name={label}
              value={value}
              desktopFilters={desktopFilters}
            />
          )}
        </Fragment>
      ))}
    </Wrap>
  );
};

export default FilterSelector;
