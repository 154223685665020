import styled from 'styled-components';

export const Wrap = styled.div`
  ${({ isCheckbox }) => `
    background-color: ${isCheckbox ? '#fff' : 'inherit'};
    padding: ${isCheckbox ? '10px' : 'auto'};
  `};
`;

export const CheckMark = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 1px solid #cccccc;
  left: 0;

  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 50%;
    top: 50%;
    width: 3px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;

export const RadioButton = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 1px solid #cccccc;
  border-radius: 50%;
`;

export const FilterInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  margin-right: 10px;

  &:checked {
    ~ ${CheckMark} {
      background-color: #0c2c64;

      &:after {
        display: block;
      }
    }
  }

  &:checked {
    ~ ${RadioButton} {
      background-color: #0c2c64;

      &:after {
        display: block;
      }
    }
  }
`;

export const Label = styled.label`
  display: flex;
  font-family: 'Lato', serif;
  color: #13346e;
  font-size: 13px;
  cursor: pointer;
  position: relative;
  padding-left: 30px;
  margin-bottom: 16px;
  text-align: left;
`;
