/* eslint-disable react/prop-types */
import React, { useContext, useEffect } from 'react';
import { Wrap, Logo, Title, Intro, CTA } from './NoResults.styles.js';

import { Context } from '../../pages/search-and-results/search-and-results';
import ContentLabel from '../../components/content-label/ContentLabel';
import ResultsIntro from '../results-intro/ResultsIntro.jsx';
import { getHostname } from '../../utility/helpers.js';

const NoResults = ({ contentFetchComplete }) => {
  const noResultsContext = useContext(Context);

  const { noResultsContent } = noResultsContext.state.contentData;
  const {
    seeAllLocations,
    bedrooms,
    priceRange,
    radius,
    developmentCount,
    plotCount,
    initialLocation,
    searchedLocation,
    selectedPropertyTypes,
    initialRadius
  } = noResultsContext.state;

  const selectedPropertyTypeValues = selectedPropertyTypes.map(property => {
    return property.value;
  });

  const globalSearchData = {
    term: '',
    minBedrooms: null,
    maxBedrooms: null,
    minPrice: null,
    maxPrice: null,
    propertyTypes: [],
    initialSearch: true,
    dataSourceId: '44f8b44c-3d42-4205-9e6a-6757e936ba41',
    radius: null
  };

  useEffect(() => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(globalSearchData)
    };

    if (seeAllLocations) {
      noResultsContext.dispatch({
        type: 'SET_IS_LOADING',
        payload: true
      });
      noResultsContext.dispatch({
        type: 'RESET_FILTERS'
      });

      fetch(`${getHostname()}/api/v1/locations/search`, requestOptions)
        .then(response => response.json())
        .then(data => {
          noResultsContext.dispatch({
            type: 'SET_PROPERTIES',
            payload: data.developments
          });
          noResultsContext.dispatch({
            type: 'SET_IS_LOADING',
            payload: false
          });
        })
        .catch(error => console.log(error));
    }
  }, [seeAllLocations]);

  useEffect(() => {
    if ((searchedLocation || initialLocation) && (window.dataLayer)) {
      window.dataLayer.push({
        event: 'SearchNoResults',
        searchQuery: searchedLocation || initialLocation,
        filterSearchRadius: `${radius || initialRadius} miles`,
        filterPriceRangeMin: priceRange.min,
        filterPriceRangeMax: priceRange.max,
        filterBedroomsMin: bedrooms.min,
        filterBedroomsMax: bedrooms.max,
        filterPropertyType: selectedPropertyTypeValues,
        countDevelopments: developmentCount,
        countPlots: plotCount
      });
    }
  }, []);

  return (
    <Wrap>
      {contentFetchComplete ? <ResultsIntro noResults /> : null}

      {noResultsContent ? (
        <>
          {noResultsContent.image ? (
            <Logo
              src={noResultsContent.image.url}
              alt={noResultsContent.image.alt}
            />
          ) : null}
          {noResultsContent.title ? (
            <Title>{noResultsContent.title}</Title>
          ) : null}{' '}
          {noResultsContent.description ? (
            <Intro>{noResultsContent.description}</Intro>
          ) : null}
          <CTA
            onClick={() => {
              let newUrl =
                window.location.protocol +
                '//' +
                window.location.host +
                window.location.pathname;

              window.history.pushState({ path: newUrl }, '', newUrl);

              noResultsContext.dispatch({
                type: 'SET_SEE_ALL_LOCATIONS',
                payload: true
              });
            }}
          >
            <ContentLabel name="seeAllLocationsLabel" />
          </CTA>
        </>
      ) : null}
    </Wrap>
  );
};

export default NoResults;
