import styled from 'styled-components';
import { minBp } from '../../lib/theme';

export const ResultsIntroComponent = styled.div`
  ${({ noResults, hasResults }) => `
    width: 100%;
    padding: 10px 0;
    margin-bottom: ${noResults || !hasResults ? '20px' : '0'};
    
    @media ${minBp('tabletLarge')} {
      border-bottom: 1px solid #e8e8e8;
      padding-top: 0;
      margin-bottom: ${noResults || !hasResults ? '70px' : '0'};
    }
  `}
`;

export const Wrap = styled.div`
  width: 100%;

  @media ${minBp('tabletLarge')} {
    display: flex;
    justify-content: space-between;
  }
`;

export const Title = styled.p`
  ${({ hasResults }) => `
  color: #13346e;
  font-size: 14px;
  text-align: center;
  margin-top: 5px;
  font-family: 'Lato', serif;
  border-bottom: ${hasResults ? '1px solid #e8e8e8' : 'none'} ;
  padding-bottom: 10px;
  margin-block-end: 0;
  padding-left: 5px;
  padding-right: 5px;

  @media ${minBp('tabletLarge')} {
    display: flex;
    align-items: center;
    text-align: left;
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding-left: 0;
    font-size: 20px;
  }
`};
`;

export const Action = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  padding: 10px;
  width: 100%;
  color: #13346e;
  font-size: 14px;
  font-family: 'Lato', serif;

  &:focus {
    outline: none;
  }

  &:first-of-type {
    border-right: 1px solid #e8e8e8;
  }

  @media ${minBp('tabletLarge')} {
    border: 1px solid #cccccc;
    padding: 10px;

    &:first-of-type {
      display: none;
    }
  }
`;

export const Image = styled.img`
  height: 16px;
`;

export const ActionWrap = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #e8e8e8;

  @media ${minBp('tabletLarge')} {
    border-bottom: none;
    display: block;
  }

  ${Action}:first-of-type {
    ${Image} {
      margin-right: 5px;
    }
  }
`;

export const CheckMark = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 1px solid #cccccc;

  @media ${minBp('additional.mobile')} {
    left: 15px;
  }

  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 50%;
    top: 50%;
    width: 3px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;

export const Checkbox = styled.input`
  position: relative;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  margin-right: 20px;

  @media ${minBp('tabletLarge')} {
    position: absolute;
  }

  &:checked {
    ~ ${CheckMark} {
      background-color: #0c2c64;

      &:after {
        display: block;
      }
    }
  }
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Lato', serif;
  color: #13346e;
  font-size: 14px;
  cursor: pointer;
  width: 100%;
  position: relative;
  padding-left: 10px;

  @media ${minBp('tabletLarge')} {
    border: 1px solid #cccccc;
    padding: 10px 10px 10px 45px;
    width: auto;
  }
`;
