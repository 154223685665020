import styled, { keyframes } from 'styled-components';

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const LoaderWrap = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Loader = styled.div`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: ${rotate360} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #0c2c64 transparent transparent transparent;
`;

export const LoadingRing = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  ${Loader}:nth-child(1) {
    animation-delay: -0.45s;
  }

  ${Loader}:nth-child(2) {
    animation-delay: -0.3s;
  }

  ${Loader}:nth-child(3) {
    animation-delay: -0.15s;
  }
`;
