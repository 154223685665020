import styled from 'styled-components';
import { minBp } from '../../lib/theme';

export const Wrap = styled.section`
  background-color: #ebebeb;
  display: flex;
  padding-bottom: 40px;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid #d6d6d6;

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }

  @media ${minBp('tabletLarge')} {
    flex-direction: row;
    padding-bottom: 20px;
    padding-top: 20px;
  }
`;

export const Inner = styled.div`
  padding: 0 20px;

  @media ${minBp('tabletLarge')} {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const Title = styled.h4`
  font-family: 'Lato', serif;
  color: #13346e;
  font-size: 18px;
  margin-bottom: 12px;
  margin-top: 16px;
`;

export const TagLine = styled.span`
  font-family: 'Lato', serif;
  font-size: 16px;
  color: #525252;
  display: block;
  margin-bottom: 12px;
`;

export const Link = styled.a`
  font-family: 'Lato', serif;
  font-size: 16px;
  color: #153372;
`;

export const ImageWrap = styled.div`
  position: relative;
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;
  max-height: 220px;

  @media ${minBp('tablet')} {
    max-height: 400px;
  }

  @media ${minBp('tabletLarge')} {
    max-width: 350px;
    max-height: none;
  }
`;

export const SpecialOffers = styled.div`
  position: absolute;
  right: 0;
  bottom: 20px;
  background-color: #0c2c64;
  color: #fff;
  font-family: 'Lato', serif;
  font-size: 12px;
  padding: 8px;
`;

export const ImageLink = styled.a``;
