import styled from 'styled-components';
import { minBp } from '../../lib/theme';

export const StatusBannerComponent = styled.div`
  ${({ status }) => `
    width: 100%;
    text-align: center;
    background-color: ${status === 'Reserved' ? '#c85b5b' : '#d1ebf0'};
    margin-bottom: 25px;
    min-height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media ${minBp('tabletLarge')} { 
      margin-bottom: 0;
    }
  `};
`;

export const Text = styled.p`
  ${({ status }) => `
    font-family: 'Lato', serif;
    color: ${status === 'Reserved' ? '#fff' : '#0c2c64'}
  `};
`;
