import styled from 'styled-components';
import { minBp } from '../../../lib/theme';

export const MobileFiltersComponent = styled.div`
  ${({ showFilterMenu, showMap }) => `
  display: flex;
  background-color: #f2f2f2;
  padding: 17px 0;
  position: ${showMap ? 'fixed' : 'sticky'};
  top: 0;
  min-height: ${showFilterMenu ? '100vh' : 'auto'};
  align-items: ${showFilterMenu ? 'flex-start' : 'stretch'};
  z-index: 9;
  width: ${showFilterMenu || showMap ? '100%' : 'auto'};
  overflow-y: ${showFilterMenu ? 'scroll' : 'inherit'};

  @media ${minBp('tabletLarge')} { 
    display: none
  }
`}
`;

export const Wrap = styled.div`
  display: flex;
  width: 100%;
  padding: 0 10px;
`;

export const InputWrap = styled.div`
  display: flex;
  position: relative;

  @media ${minBp('tablet')} {
    flex: 2;
  }

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
  }
`;

export const Input = styled.input`
  box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.09);
  padding: 12px 5px 12px 5px;
  border: none;
  flex: 2;
  font-family: 'Lato', serif;
  color: #153372;
  font-size: 14px;

  &:focus {
    outline: none;
  }

  @media ${minBp('additional.mobile')} {
    padding: 12px 30px 12px 5px;
  }
`;

export const InputIcon = styled.img`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
`;

export const InputButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  &:focus {
    outline: none;
  }
`;

export const FilterButton = styled.button`
  ${({ showFilterMenu }) => `
    background-color: #0c2c64;
    color: #fff;
    padding: 12px 10px;
    margin-left: 8px;
    flex: 1;
    font-family: 'Lato', serif;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus {
      outline: none;
    }

    svg {
      margin-left: 5px;
      margin-top: 2px;
      width: 11px;
      height: 11px;
      transform: ${showFilterMenu ? 'rotate(180deg)' : 'none'};

      g {
        fill: #fff;
      }
  `}
`;

export const FilterButtonText = styled.span`
  font-family: 'Lato', serif;
`;

export const FilterMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  top: 100px;
  width: 100%;
  height: 100%;
`;

export const FilterInner = styled.div`
  @media ${minBp('tablet')} {
    margin-bottom: 250px;
  }
`;

export const FilterOptionWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 20px);
  margin: 0 auto;

  &:not(:first-child) {
    margin-top: 25px;
  }

  &:last-child {
    margin-bottom: 250px;
  }

  select {
    font-family: 'Lato', serif;
    color: #1d3c7c;
    font-size: 14px;
    background-color: #fff;
    border-radius: 0;
    border: none;
    padding: 10px 15px;
    width: 100%;
    margin: 0 auto;
  }
`;

export const FilterLabelWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  svg {
    margin-left: 10px;
  }
`;

export const FilterLabel = styled.span`
  font-family: 'Lato', serif;
  color: #1d3c7c;
  font-size: 14px;
  margin-left: 10px;
`;

export const FilterSelectWrap = styled.div`
  display: flex;

  select {
    width: 50%;

    &:not(:first-child) {
      margin-left: 10px;
    }
  }
`;

export const FilterSelect = styled.select``;

export const FilterCheckbox = styled.div`
  background-color: #fff;
  padding: 16px 17px;
`;

export const Icon = styled.img``;

export const ActionWrap = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #0c2d60;

  @media ${minBp('tabletLarge')} {
    display: none;
  }
`;

export const ActionInner = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  margin: 0 30px;
`;

export const ClearBtn = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const ClearBtnText = styled.span`
  text-decoration: underline;
  font-family: 'Lato', serif;
  color: #fff;
  font-size: 15px;
`;

export const ApplyBtn = styled.button`
  background: #fff;
  padding: 6px 30px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const ApplyBtnText = styled.span`
  font-family: 'Lato', serif;
  color: #565656;
  font-size: 15px;
`;

export const ResultsWrap = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50px;
  width: 150%;
  background-color: #fff;
  box-shadow: 2px 2px 20px 2px rgba(0, 0, 0, 0.09);
  z-index: 1;
  overflow-y: scroll;
  max-height: 400px;
`;

export const Result = styled.div`
  padding: 15px 15px 15px 30px;
  color: #1f3b80;
  font-family: 'Lato', serif;
  width: 100%;
  cursor: pointer;
`;

export const ResultsInner = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;

  &:last-of-type {
    border-bottom: 0;
  }
`;

export const ResultIcon = styled.img`
  height: 13px;
  width: 9px;
  position: absolute;
  left: 10px;
`;
