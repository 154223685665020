import styled from 'styled-components';
import { minBp } from '../../lib/theme';

export const Wrap = styled.div`
  ${({ selectedHouse, showAllHomes, isOpenStatus }) => `
    background-color: ${
      (isOpenStatus && selectedHouse) || (isOpenStatus && showAllHomes)
        ? '#EBEBEB'
        : '#fff'
    };
    display: flex;
    flex-direction: column;
    padding-bottom: ${selectedHouse ? '40px' : '16px'};
    width: 100%;

    @media ${minBp('tabletLarge')} {
      flex-direction: row;
      margin-top: 45px;
    }
  `};
`;

export const Inner = styled.div`
  padding: 20px 20px 0;

  @media ${minBp('tabletLarge')} {
    width: 100%;
    padding: 30px 20px 0;
  }
`;

export const Title = styled.h4`
  font-family: 'Lato', serif;
  color: #13346e;
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
`;

export const Location = styled.p`
  font-family: 'Lato', serif;
  color: #13346e;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 8px;

  @media ${minBp('tabletLarge')} {
    margin-bottom: 20px;
  }
`;

export const TagLine = styled.span`
  font-family: 'Lato', serif;
  font-size: 16px;
  color: #525252;
  display: block;
`;

export const InfoWrap = styled.div`
  display: flex;
  margin-top: 16px;

  @media ${minBp('tabletLarge')} {
    flex-direction: column;
  }
`;

export const Distance = styled.span`
  font-family: 'Lato', serif;
  color: #13346e;
  display: flex;
  align-items: center;
  flex: 1;

  svg {
    margin-right: 10px;
  }
`;

export const CTA = styled.a`
  background-color: #0c2c64;
  color: #fff;
  padding: 10px;
  margin-left: 8px;
  flex: 1;
  font-family: 'Lato', serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  @media ${minBp('tabletLarge')} {
    align-self: flex-start;
    margin-top: 15px;
  }

  &:focus {
    outline: none;
  }

  @media ${minBp('tabletLarge')} {
    flex: none;
  }
`;

export const ImageWrap = styled.div`
  position: relative;
`;

export const Image = styled.img`
  @media ${minBp('tabletLarge')} {
    width: 100%;
    max-width: 500px;
  }
`;

export const Icon = styled.img`
  margin-right: 10px;
  max-width: 17px;
`;

export const ImageLink = styled.a``;

export const TitleLink = styled.a`
  text-decoration: none;

  &:visited {
    color: purple;
  }
`;
