import React from 'react';
import { StatusBannerComponent, Text } from './StatusBanner.styles.js';

const StatusBanner = ({ status }) => {
  return status ? (
    <StatusBannerComponent status={status}>
      <Text status={status}>{status}</Text>
    </StatusBannerComponent>
  ) : null;
};

export default StatusBanner;
