/* eslint-disable react/prop-types */
import React from 'react';
import StandaloneMap from './StandaloneMap';
import { MapContainer, MapWrap, ContainerElement } from './Map.styles';

const StandaloneWrappedMap = ({ error, results, contentData }) => {
  return (
    <MapContainer>
      <MapWrap>
        <StandaloneMap
          googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDBRj0yyBtQ50o8geSjePHVbKr_7zXsrrk"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<ContainerElement />}
          mapElement={<div style={{ height: `100%` }} />}
          error={error}
          results={results}
          contentData={contentData}
        />
      </MapWrap>
    </MapContainer>
  );
};

export default StandaloneWrappedMap;
