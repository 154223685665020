/* eslint-disable react/prop-types */
import React, { useState, useContext } from 'react';
import { Wrap, Inner } from './Results.styles.js';

import WrappedMap from '../../components/map/WrappedMap';
import ResultsSection from '../../components/results-section/ResultsSection';
import NoResults from '../no-results/NoResults.jsx';

import { Context } from '../../pages/search-and-results/search-and-results';
import ErrorMessage from '../error-message/ErrorMessage.jsx';

const Results = ({ isFilterSticky, error, contentFetchComplete, goalEndpoint }) => {
  // stores lat,lng and hover state for location that is currently being hovered over.
  const [developmentHoverLocation, setDevelopmentHoverLocation] = useState(
    null
  );
  const [developmentHover, setDevelopmentHover] = useState(false);

  const resultsContext = useContext(Context);

  const { originalData } = resultsContext.state;
  const homeCount = originalData.reduce(
    (prevHomeCount, development) => prevHomeCount + ((development.homes && development.homes.length) || 0),
    0
  );

  return (
    <Wrap isFilterSticky={isFilterSticky}>
      <Inner>
        {error ? (
          <ErrorMessage />
        ) : originalData && originalData.length > 0 && homeCount > 0 ? (
          <ResultsSection
            goalEndpoint={goalEndpoint}
            setDevelopmentHover={setDevelopmentHover}
            setDevelopmentHoverLocation={setDevelopmentHoverLocation}
          />
        ) : (
          <NoResults contentFetchComplete={contentFetchComplete} />
        )}
        <WrappedMap
          isFilterSticky={isFilterSticky}
          developmentHover={developmentHover}
          developmentHoverLocation={developmentHoverLocation}
          error={error}
        />
      </Inner>
    </Wrap>
  );
};

export default Results;
