/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
  InfoWindow
} from 'react-google-maps';

import CurrentDevelopmentMarker from '../../assets/icons/CurrentDevelopmentMarker.svg';
import ComingSoonMarker from '../../assets/icons/ComingSoonMarker.svg';
import SelectedMarker from '../../assets/icons/SelectedMarker.svg';

import InfoWindowContent from '../info-window-content/InfoWindowContent.js';
import MapHelpers from './MapHelpers.js';

function GoogleMaps({ error, results, contentData }) {
  const [selectedLocation, setSelectedLocation] = useState(null);

  function handleSelected(location) {
    setSelectedLocation(location);
  }

  function handleMarkerIcon(location) {
    if (!location.status) {
      return;
    }

    return {
      url:
        location.status === 'Open'
          ? CurrentDevelopmentMarker
          : ComingSoonMarker,
      scaledSize: new window.google.maps.Size(30, 40)
    };
  }

  return (
    <>
      <GoogleMap
        defaultZoom={5}
        defaultCenter={{
          lat: 53.4807593,
          lng: -2.2426305
        }}
        options={{
          streetViewControl: false,
          mapTypeControl: true,
          fullscreenControl: false,
          mapTrpeId: 'hybrid',
        }}
        style="flex: 1"
      >
        {results
          ? results.developments.map((location, i) => {
              const latitude = parseFloat(location.latitude);
              const longitude = parseFloat(location.longitude);

              return (
                <React.Fragment key={i}>
                  {!error ? (
                    <Marker
                      position={{
                        lat: latitude,
                        lng: longitude
                      }}
                      onClick={() => {
                        handleSelected(location, i);
                        window.dataLayer.push({
                          event: 'MapMarkerOpen',
                          developmentName: location.name
                        });
                      }}
                      icon={handleMarkerIcon(location)}
                    />
                  ) : null}
                </React.Fragment>
              );
            })
          : null}
        {selectedLocation ? (
          <InfoWindow
            position={{
              lat: parseFloat(selectedLocation.latitude),
              lng: parseFloat(selectedLocation.longitude)
            }}
            options={{
              pane: 'overlayLayer',
              pixelOffset: new window.google.maps.Size(0, -30),
              alignBottom: true,
              boxStyle: {
                width: '400px'
              },
              closeBoxURL: ''
            }}
            onCloseClick={() => {
              handleSelected(null, null);

              window.dataLayer.push({
                event: 'MapMarkerClose',
                developmentName: selectedLocation.name
              });
            }}
          >
            <InfoWindowContent
              selectedLocation={selectedLocation}
              contentData={contentData}
            />
          </InfoWindow>
        ) : null}
      </GoogleMap>
      <MapHelpers contentData={contentData} />
    </>
  );
}

const Map = withScriptjs(withGoogleMap(GoogleMaps));

export default Map;
