/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import DevelopmentCard from '../development-card/DevelopmentCard.jsx';
import Accordion from '../accordion/Accordion.jsx';
import StatusBanner from '../status-banner/StatusBanner.jsx';

const DevelopmentPlotGroup = ({
  development,
  showAllHomes,
  setDevelopmentHover,
  setDevelopmentHoverLocation,
  goalEndpoint,
}) => {
  const [selectedHouse, setSelectedHouse] = useState(null);

  return (
    <>
      <DevelopmentCard
        name={development.name}
        location={development.location}
        tagline={development.tagline}
        url={development.url}
        distance={development.distance}
        lat={development.latitude}
        lng={development.longitude}
        image={development.image}
        status={development.status}
        selectedHouse={selectedHouse}
        showAllHomes={showAllHomes}
        setDevelopmentHover={setDevelopmentHover}
        setDevelopmentHoverLocation={setDevelopmentHoverLocation}
      />
      {development.status === 'Open' &&
      development.homes &&
      development.homes.length > 0 ? (
        <Accordion
          goalEndpoint={goalEndpoint}
          homes={development.homes}
          setSelectedHouse={setSelectedHouse}
          showAllHomes={showAllHomes}
          name={development.name}
        />
      ) : development.status === 'Coming Soon' ? (
        <StatusBanner status={development.status} />
      ) : null}
    </>
  );
};

export default DevelopmentPlotGroup;
