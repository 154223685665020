import styled from 'styled-components';
import { minBp } from '../../lib/theme';

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const TitleWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const Icon = styled.img`
  margin-right: 10px;
  height: 12px;
  width: 12px;
`;

export const Title = styled.span`
  font-size: 13px;
  color: #13346e;
`;

export const DropdownOuter = styled.div`
  display: flex;

  @media ${minBp('tabletLarge')} {
    display: block;
  }
`;

export const DropdownWrap = styled.div`
  display: flex;
  width: 100%;

  &:first-of-type {
    margin-right: 10px;
  }

  @media ${minBp('tabletLarge')} {
    width: auto;
    margin: 10px 0 16px;
  }
`;

export const ActionWrap = styled.div`
  width: 100%;
  display: none;

  @media ${minBp('tabletLarge')} { 
    display: block;
  }
  
  &:before {
    content '';
    width: 100%;
    height: 1px;
    background-color: #e9e9e9;
    display: block;
    position: absolute;
    left: 0;
  }
`;

export const ActionInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
  width: 100%;
`;

export const ClearBtn = styled.a`
  background: transparent;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const ClearBtnText = styled.span`
  text-decoration: underline;
  font-family: 'Lato', serif;
  color: #13346e;
  font-size: 13px;
`;

export const ApplyBtn = styled.a`
  background: #0c2d60;
  padding: 6px 30px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const ApplyBtnText = styled.span`
  font-family: 'Lato', serif;
  color: #fff;
  font-size: 13px;
`;
