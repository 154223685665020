import styled from 'styled-components';
import { minBp } from '../../lib/theme';

export const ContentWrap = styled.div`
  background-color: #ebebeb;
  overflow: hidden;
  transition: max-height 0.2s ease;
`;

export const AccordionComponent = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Button = styled.button`
  ${({ isActive, showAllHomes, showBtn }) => `
    background-color: #fff;
    color: #153372;
    cursor: pointer;
    padding: 10px 20px;
    display: ${showAllHomes && showBtn && isActive ? 'none' : 'flex'};
    align-items: center;
    justify-content: center;
    border-left: none;
    border-right: none;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    outline: none;
    transition: background-color 0.2s ease;
    position: relative;
    margin-bottom: 25px;

    &:focus {
      outline: none;
    }

    @media ${minBp('tabletLarge')} {
      margin-top: 10px;
      margin-bottom: ${isActive ? '20px' : '0'};
    }

    img {
      height: 11px;
      width: 11px;
      top: auto;
      position: relative; 
      transform: ${isActive ? 'rotate(180deg)' : ''};
    }
  `}
`;
export const Title = styled.p`
  font-family: 'Lato', serif;
  font-size: 18px;
  color: #0c2c64;
  background-color: #ebebeb;
  margin: 0;
  padding: 0 20px 25px;

  @media ${minBp('tabletLarge')} {
    padding: 0 0 0 10px;
  }
`;

export const Text = styled.p`
  font-family: 'Lato', serif;
  font-size: 14px;
  margin: 0;
`;

export const Icon = styled.img`
  margin-left: 20px;
`;
