import React from 'react';
import { LoaderWrap, LoadingRing, Loader } from './LoadingSpinner.styles.js';

const LoadingSpinner = () => (
  <LoaderWrap>
    <LoadingRing>
      <Loader></Loader>
      <Loader></Loader>
      <Loader></Loader>
      <Loader></Loader>
    </LoadingRing>
  </LoaderWrap>
);
export default LoadingSpinner;
