/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { CheckMark, FilterInput, Label } from './FilterCheckbox.styles.js';
import { Context } from '../../pages/search-and-results/search-and-results.js';

const FilterCheckbox = ({ name, value }) => {
  const checkboxContext = useContext(Context);

  const { selectedPropertyTypes } = checkboxContext.state;

  const selectedPropertyTypeValues = selectedPropertyTypes.map(property => {
    return property.value;
  });

  const checked = selectedPropertyTypeValues.includes(value);

  return (
    <Label>
      <FilterInput
        type="checkbox"
        name={name}
        onChange={() => {
          if (checked) {
            checkboxContext.dispatch({
              type: 'REMOVE_SELECTED_PROPERTY_TYPE',
              payload: { value }
            });
            return;
          }
          checkboxContext.dispatch({
            type: 'SET_SELECTED_PROPERTY_TYPE',
            payload: { value }
          });
        }}
        checked={checked}
      />
      {name}
      <CheckMark />
    </Label>
  );
};

export default FilterCheckbox;
