import styled from 'styled-components';
import tw from 'tailwind.macro';
import { minBp, maxBp } from '../../lib/theme';

export const InfoWindowContentComponent = styled.section`
  ${tw``};
`;

export const Wrap = styled.div`
  display: flex;
  width: 100%;

  @media ${maxBp('tablet')} {
    position: fixed;
    bottom: 0px;
    background: white;
    font-size: 10px;
  }
`;

export const ImgWrap = styled.div`
  @media ${maxBp('tablet')} {
    display: flex;
  }

  width: 50%;
  position: relative;
`;

export const Img = styled.img`
  width: 100%;

  @media ${maxBp('tablet')} {
    object-fit: cover;
    height: 100%;
  }

  @media ${minBp('tablet')} {
    ${({ viewDevelopmentsHeight }) => `
      height: ${
        viewDevelopmentsHeight
          ? `calc(100% - ${viewDevelopmentsHeight}px)`
          : 'calc(100% - 38px)'
      };
      margin-bottom: 0;
    `};
  }
`;

export const Content = styled.div`
  width: 50%;
  margin: 10px 0 0 10px;

  @media ${minBp('additional.desktopLarge')} {
    padding-right: 30px;
  }
`;

export const ViewDevelopments = styled.a`
  position: absolute;
  bottom: 0px;
  left: 0;
  z-index: 1;
  background-color: #842752;
  color: white;
  padding: 10px 0;
  display: flex;
  width: 100%;
  justify-content: center;
  text-decoration: none;
  font-family: 'Lato', serif;
`;

export const ViewDevelopmentsMobileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #842752;
  white-space: pre;
  width: 30px;
  margin-left: 10px;
`;

export const ViewDevelopmentsMobile = styled.a`
  transform: rotate(90deg);
  text-align: center;
  display: flex;
  color: white;
  text-decoration: none;
  font-family: 'Lato', serif;
`;

export const ViewDevelopmentsClose = styled.button`
  height: 25px;
  width: 25px;
  top: -24px;
  left: 50%;
  margin-left: -10px;
  position: absolute;
  display: flex;
  justify-content: center;
  border: none;
  background: white;
  padding: 6px;
`;

export const ViewDevelopmentsCloseIcon = styled.img``;

export const Title = styled.h5`
  color: #153372;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 0px;
  margin-top: 0;
  font-family: 'Lato', serif;
  margin-right: 30px;
`;

export const Intro = styled.p`
  color: #153372;
  font-size: 12px;
  margin: 6px 0;
  display: block;
  font-family: 'Lato', serif;
`;

export const DescriptionWrap = styled.div`
  display: block;
`;

export const Description = styled.span`
  display: block;
  margin-bottom: 3px;
  font-family: 'Lato', serif;
  color: #5c5c5c;
`;
