/* eslint-disable react/prop-types */
import React, { useState, useContext, useEffect } from 'react';
import { useDebounce, getHostname, resolve } from '../../utility/helpers';

import MobileFilters from './Mobile/MobileFilters';
import DesktopFilters from './Desktop/DesktopFilters';
import { Context } from '../../pages/search-and-results/search-and-results';

const Filters = ({ isFilterSticky, setIsFilterSticky, onFetchProperties }) => {
  const filterContext = useContext(Context);

  const {
    query,
    contentData: { filters }
  } = filterContext.state;

  function handleSaveFilter() {
    filterContext.dispatch({
      type: 'SET_ACTIVE_FILTER',
      payload: null
    });

    onFetchProperties();
  }

  function handleSearchTrigger(location) {
    const searchedLocation = location || query;

    filterContext.dispatch({
      type: 'TOGGLE_SEARCH_INPUT',
      payload: false
    });
    filterContext.dispatch({
      type: 'SET_SEARCHED_LOCATION',
      payload: searchedLocation
    });

    updateQueryParam(searchedLocation);
    onFetchProperties();
  }

  function handleKeyPressed(event) {
    if (event.key === 'Enter') {
      handleSearchTrigger();
      updateQueryParam(event.target.value);
    }
  }

  function updateQueryParam(location) {
    let newUrl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname;

    if (location) {
      newUrl += '?location=' + location;
    }

    window.history.pushState({ path: newUrl }, '', newUrl);
  }

  const handleQueryChange = value => {
    filterContext.dispatch({
      type: 'TOGGLE_SEARCH_INPUT',
      payload: true
    });

    filterContext.dispatch({
      type: 'SET_QUERY',
      payload: value
    });
  };

  const [results, setResults] = useState([]);
  const debouncedSearchTerm = useDebounce(query, 500);

  useEffect(() => {
    if (debouncedSearchTerm) {
      fetch(
        `${getHostname()}/bloorapi/autocomplete?query=${debouncedSearchTerm}`
      )
        .then(response => response.json())
        .then(data => {
          setResults(data);
        })
        .catch(error => console.log(error));
    } else {
      setResults([]);
    }
  }, [debouncedSearchTerm]);

  return filters ? (
    <>
      <MobileFilters
        filters={filters}
        onSearchTrigger={handleSearchTrigger}
        handleKeyPressed={handleKeyPressed}
        onQueryChange={handleQueryChange}
        results={results}
        onFetchProperties={onFetchProperties}
      />
      <DesktopFilters
        filters={filters}
        isFilterSticky={isFilterSticky}
        setIsFilterSticky={setIsFilterSticky}
        handleSaveFilter={handleSaveFilter}
        onSearchTrigger={handleSearchTrigger}
        onQueryChange={handleQueryChange}
        handleKeyPressed={handleKeyPressed}
        results={results}
        onFetchProperties={onFetchProperties}
      />
    </>
  ) : null;
};

export default Filters;
