import styled from 'styled-components';
import { minBp } from '../../lib/theme';

export const Wrap = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;

  @media ${minBp('tabletLarge')} {
    max-width: calc(100% - 50px);
    margin-top: 50px;
  }

  @media ${minBp('desktop')} {
    max-width: calc(100% - 140px);
  }
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;
