/* eslint-disable react/prop-types */
import React from 'react';

import { IconContainer, IconInner, Icon, IconText } from './Map.styles';

import SearchLocation from '../../assets/icons/SearchLocation.svg';
import CurrentDevelopmentMarker from '../../assets/icons/CurrentDevelopmentMarker.svg';
import ComingSoonMarker from '../../assets/icons/ComingSoonMarker.svg';

import ContentLabel from '../content-label/ContentLabel';

const MapHelpers = ({ contentData }) => (
  <IconContainer>
    <IconInner>
      <Icon src={CurrentDevelopmentMarker} />
      <IconText>
        {contentData && contentData.labels.currentDevelopmentsLabel ? (
          <ContentLabel name={contentData.labels.currentDevelopmentsLabel} />
        ) : null}
        {!contentData ? <ContentLabel name="currentDevelopmentsLabel" /> : null}
      </IconText>
    </IconInner>
    <IconInner>
      <Icon src={ComingSoonMarker} />
      <IconText>
        {contentData && contentData.labels.comingSoonLabel ? (
          <ContentLabel name={contentData.labels.comingSoonLabel} />
        ) : null}
        {!contentData ? <ContentLabel name="comingSoonLabel" /> : null}
      </IconText>
    </IconInner>
    <IconInner>
      <Icon src={SearchLocation} />
      <IconText>
        {contentData && contentData.labels.searchLocationLabel ? (
          <ContentLabel name={contentData.labels.searchLocationLabel} />
        ) : null}
        {!contentData ? <ContentLabel name="searchLocationLabel" /> : null}
      </IconText>
    </IconInner>
  </IconContainer>
);

export default MapHelpers;
