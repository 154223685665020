import styled from 'styled-components';

export const RadioButton = styled.span`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 1px solid #cccccc;
  border-radius: 50%;
`;

export const FilterInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  margin-right: 10px;

  &:checked {
    ~ ${RadioButton} {
      background-color: #0c2c64;

      &:after {
        display: block;
      }
    }
  }
`;

export const Label = styled.label`
  display: flex;
  font-family: 'Lato', serif;
  color: #13346e;
  font-size: 13px;
  cursor: pointer;
  position: relative;
  padding-left: 30px;
  margin-bottom: 16px;
  text-align: left;
`;
